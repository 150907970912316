import React from "react";
import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import { category_create, category_update, category_delete, all_category_list } from "./../../../actions/Masters/category";
import { useSelector } from "react-redux";
import ViewChange from "../../utils/ViewChange";
import { useDispatch } from "react-redux";
import { TableData } from "./TableData";
import { formStructure } from "./FormStructure";
import { useEffect } from "react";
import { useLocation ,useNavigate} from "react-router-dom";
export default function Category() {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate()
	const [tableData, setTableData] = useState({
		...TableData(), 
		// deleteRecord: category_delete, 
		// updateRecord: category_update,
		onDeleteText: "Are you sure want to delete the Category?",
		disableDelete: true,
		customisedStatusUpdateMessage: true,
		onActiveText: "Videos will be visible to all users when you activate this category, are you sure want to change it ?",
		onInactiveText: ["Videos are associated with this category, are you still want to change it.?"],
	})
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [view, setView] = useState(location?.state?.view || "view_all");
	useMemo(() => {
		if (isEdit) { setView("create_new") ;}
		 

		else {
			setView("view_all")
			// setForm({ sequence: Number(tableData.tableBody[tableData.tableBody.length - 1]?.["sequence"]) + 1 });
		}
	}, [isEdit]);
	const path = location?.pathname.split("/")[3]
	useEffect(() => {
		setView(path != "Category" ? "create_new" : "view_all");
		// console.log(Number(tableData.tableBody[tableData.tableBody.length - 1]?.["sequence"]) + 1 ,tableData ,(location?.stat/e?.form && JSON.parse(location?.state?.form)),"Ndfds") 
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
		setIsEdit(path == "EditCategory");
	}, [location]);



	const formTitle = isEdit ? "Edit Category" : "Create Category";



	useEffect(() => {
		dispatch(all_category_list())
	}, [])


	const categories = useSelector((state) => state.masters.categories);

	useMemo(() => {

		if (categories?.statuscode == 200) {
			const temp = tableData
			temp.tableBody = categories?.data.map((ele) => ({ ...ele, edit: ['HOME', 'SHOWS', 'NEWS', 'INTERVIEWS'].includes(ele.category_name) }))
			setTableData({ ...temp })
			// console.log(tableData, "category")
			setForm({ ...form})
		}
	}, [categories])
// useMemo(() => {
// 		if(form?.category_name !=undefined){
// 			setForm({
// 				...form,
// 				category_name:form?.category_name.replace(/(^[\s]+|[\s]+$)/g, '')
// 			})
// 		}
// 	}, [form?.category_name])

	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {

		event.preventDefault();
		const data = new FormData();
		const temp_movie_category = form;
		temp_movie_category["tempcategory_name"] = form?.["category_name"];
		temp_movie_category["category_name"] = form?.["category_name"].replace(/(^[\s]+|[\s]+$)/g, '')
		setForm({
			...temp_movie_category,
		});
		Object.keys(form).map((key) => data.append(key, form?.[key]));
    
		const temp_form1 = form;
		temp_form1["category_name"] = form?.["tempcategory_name"];
		setForm({
			...temp_form1,
		});
		if (isEdit) {
			dispatch(category_update(data))
		}
		else {
			dispatch(category_create(data))
		}


	};
	useMemo(() => {
		if (message?.statuscode == 200) {

			const temp = tableData;
			if (isEdit) {
				temp.tableBody.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);

			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};

			}
			setTableData({ ...temp });
			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				dispatch(all_category_list());
				navigate("/Masters/Category/Category", { state: { view: "view_all" } })
				setView("view_all");
			}, 1000);
		}
		else if (message?.statuscode == 406) {
			setTimeout(() => {
				dispatch(all_category_list());
				navigate("/Masters/Category/Category", { state: { view: "view_all" } })
				setView("view_all");
			}, 1000);
		}
		else {

			setForm({ ...form });
		}
	}, [message])
	return (
		<>

			{/* <ViewChange
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
				create_new={"/Masters/Category/CreateCategory"}
				view_all={"/Masters/Category/Category"}
				form={form}
			/> */}

			{view == "create_new" && (
				<Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/Masters/Category/EditCategory"}
				/>
			)}


		</>
	);
}

import React from "react";
import { useSelector } from "react-redux";
export const FormStructure = (isEdit) => {
  const role = useSelector((state) => state.layout.role);
  return [
   
    {
      id: "1",
      type: "select",
      title: "Channel Category",
      name: "category",
      options: [],
      required: true
  },
  {
      id: "2",
      type: "select",
      title: "Channel Sub Category",
      name: "subcategory",
      options: [],
      required: true
  },
  
    {
      id: "3",
      type: "inputBox",
      title: "Channel Name",
      name: "channel_name",
      maxLength: "25",
      // regex: /^[a-zA-Z0-9\s\&]+$/,
      isCapitalise: true,
      required: true,
    },
    {
      id: "4",
      type: "select",
      title: "Channel Access",
      name: "channel_access",
      options: ["Free", "Paid"],
      required: true,
    },
    {
      id: "5",
      type: "inputBox",
      title: "Chaneel Price",
      name: "channel_price",
      regex: /^[0-9\.]+$/,
      maxLength: "3",
      display: "none",
    },
    {
      id: "6",
      type: "select",
      title: "Language",
      name: "language",
      options: ["English", "Hindi", "Gujarati"],
      required: true,
    },
    {
      id: "7",
      type: isEdit ? "lockedInput" : "inputBox",
      title: "Channel Link",
      // endsWith: ".mp4",
      // endsWith1: ".mkv",
      // errorText: "File extension should be .mp4 or .mkv only",
      name: "channel_url",
      required: true,
    },
    {
      id: "12",
      type: "select",
      title: "Channel Genre",
      name: "genre",
      options: [],
      required: true
    },
    {
      id: "8",
      type: "inputBox",
      title: "LCN",
      name: "channel_number",
      regex: /^[0-9\.]+$/,
      maxLength: "5",
    },
    {
      id: "9",
      type: "toggle",
      title: "Set On Dashboard",
      name: "set_on_dashboard",
      size: "3",
      default: "Yes",
      options: [
        { value: "Yes", color: "success" },
        { value: "No", color: "danger" },
      ],
      required: true,
    },

    {
      id: "10",
      type: "toggle",
      title: "Status",
      display: "block",
      name: "status",
      default: "Active",
      display: isEdit ? "block" : "none",
      size: "3",
      options: [
        { value: "Active", color: "success" },
        { value: "Inactive", color: "danger" },
      ],
    },

    {
      id: "11",
      type: "image",
      title: "Channel Image",
      subtitle: "(Resolution : 512px x 512px)",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      name: "channel_image",
      
      size: "3",

      required: true,
    },
    
   
    {
      id: "19",
      type: "button",
      title: "Create",
    },
  ];
};

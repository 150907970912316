import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import SliderBanner from '../components/SliderBanner/SliderBanner';

function SliderBannerModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateSliderBanner/' element={<Layout children={SliderBanner()} />} />
                < Route path='/EditSliderBanner/' element={<Layout children={SliderBanner()} />} />
                < Route path='/SliderBanner/' element={<Layout children={SliderBanner()} />} />
            </Routes>
        </div>
    )
}
export default SliderBannerModule
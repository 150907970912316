
import { useSelector } from "react-redux"

export const TableData = () => {
  const rights = useSelector((state) => state.layout.rights)
  const role = useSelector((state) => state.layout.role)

  return {
    tableTitle: "Channels",
    // deleteAccess: rights?.["Web Series"]?.["delete"] == "true",
    tableHead: [
      {
        id: 'channel_name',
        label: 'Channel Name',
        link:"/Channel/ChannelDetails",
        // color:"#4267B2"
      },
      {
        id: 'channel_image',
        label: 'Image',
        isImage: true
      },
      {
        id: 'set_on_dashboard',
        label: 'on Dashboard',
      },
      {
        id: 'channel_number',
        label: 'LCN',
      },
      {
        id: 'channel_access',
        label: 'Access Type',
      },
      {
        id: 'subcategory',
        label: 'Category',
        subText : 'category'
      },


      {
        id: 'language',
        label: 'Language',
      },
      {
        id: 'status',
        label: 'Status',
        isButtonDisplay: true

      },
      role !== "Distributor" &&    {
        id: 'edit',
        label: 'Update',
        access: rights?.["Web Series"]?.["edit"] == "true"
      },
    ],
    tableBody: [
    
    ],
    filterColumn: [
      {
        id: "1",
        title: "Access Type",
        name: "channel_access",
        options: ["Free", "Paid"],
      },
      {
        id: "2",
        title: "Language",
        name: "language",
        options: ["English", "Hindi", "Gujarati"],
      },
      {
        id: "3",
        title: "Category",
        name: "category",
        options: ["Action", "Comedy", "Drama", "Horror"],
      },
      // {
      //   id: "4",
      //   title: "Sub Category",
      //   name: "subcategory",
      //   options: ["Action", "Comedy", "Drama", "Horror"],
      //   displayOn: "category",
      // },
    ]
  }
}
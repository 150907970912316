import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import  DashboardIcon  from "./../../../images/dashboard_SideMenu.png";
import  WebSeriesIcon  from "./../../../images/Webseries_SideMenu.png";
import  WatchhoursIcon  from "./../../../images/watchhours_SideMenu.png";
import  TransactionIcon  from "./../../../images/Transactions_SideMenu.png";
import  SubscriptionIcon  from "./../../../images/subscription_SideMenu.png";
import  MovieIcon  from "./../../../images/Movie_SideMenu.png";
import ShortSeriesIcon from "./../../../images/Short_Stories.png"
import  MastersIcon  from "./../../../images/Masters_SideMenu.png";
import  CustomersIcon  from "./../../../images/customers_SideMenu.png";
import  ComplaintIcon  from "./../../../images/Complaint_SideMenu.png";
import SettingIcon from './../../../images/Setting_SideMenu.png'
import SeriesIcon from './../../../images/Series_SideMenu.png'
import SeasonIcon from './../../../images/Season_SideMenu.png'
import WebEpisodeIcon from './../../../images/WebEpisode_SideMenu.png'


import SliderBannerIcon from './../../../images/SliderBanner_SideMenu.png'
import ProducerIcon from "./../../../images/Distributors_SideMenu.png"
export const SidebarData= () => { 
 
  return [
  {
    title: "Dashboard",
    path: "/Dashboard/Dashboard",
    icon: <img src={DashboardIcon} height={"27px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
 
    
  },
 
  {
    title: "Channel",
    path: "/Channel/Channel",
    icon: <img src={MovieIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
   
  },
  
 
  {
    title: "Customers",
    path: "/Customer/Customer",
    icon: <img src={CustomersIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    
  },
  

  {
    title: "Masters",
    path: "/Masters/",
    icon:<img src={MastersIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    subNav: [
 
    
      {
        title: "Category",
        path: "/Masters/Category/Category",
      },
      {
        title: "Sub Category",
        path: "/Masters/SubCategory/SubCategory",
      },
      {
        title: "Intro Video",
        path: "/Masters/introVideo",
      },
      {
        title: "Language",
        path: "/Masters/Language/Language",
      },
      {
        title: "Genre",
        path: "/Masters/Genre/Genre",
      },
      // {
      //   title: "City",
      //   path: "/Masters/City/City",
      // },
      // {
      //   title: "Area",
      //   path: "/Masters/Area/Area",
      // },
      // {
      //   title: "Locality",
      //   path: "/Masters/Locality/Locality",
      // },
      
    ].filter(e=>e),
  },
  {
    title: "Settings",
    path: "/Settings/",
    icon: <img src={SettingIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    subNav: [
      
      {
        title: "About Us",
        path: "/Settings/AboutUs/",
      },
      
      {
        title: "Privacy Policy",
        path: "/Settings/PrivacyPolicy/",
      },
      {
        title: "Terms & Conditions",
        path: "/Settings/TermsConditions/",
      },
      {
        title: "Refund Policy",
        path: "/Settings/RefundPolicy/",
      },
     
    
    ].filter(e=>e),
  },
].filter(e=>e);}



import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CategoryModule from './CategoryModule';
import SubCategoryModule from './SubCategoryModule';
import LanguageModule from './LanguageModule';
import GenreModule from './GenreModule';
import IntroVideo from '../components/Masters/IntroVideo/IntroVideo';
import Layout from '../components/Layout/Layout';
function MastersModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Category/*' element={<CategoryModule key="Category"/>}/>
< Route path='/SubCategory/*' element={<SubCategoryModule key="SubCategory"/>}/>
< Route path='/Language/*' element={<LanguageModule key="Language"/>}/>
< Route path='/Genre/*' element={<GenreModule key="Genre"/>}/>
< Route path='/introVideo' element={<Layout children={IntroVideo()} />}/>


{/* <Route path='/Area/*' element ={<AreaModule key="Area"/>}/> */}
{/* <Route path='/City/*' element= {<CityModule key="City"/>}/> */}

      </Routes>
    </div>
  );
}

export default MastersModule;

import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

const Features = ({earning}) => {
  console.log(earning)
  const data=earning?.Data
  const plan=data?.movie_access
  const FeaturesData = [
  // {
  //     id: "2",
  //     subTitle: earning?.view_count,
  //     title: "Total View",
    
  //   },
  {
      id: "3",
    subTitle: earning?.total_count,
      title: "Total Redirect",
    
    },
  ];
  return (
    <>
      <Grid
        container item
        justifyContent="center"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        sx={{mt:0}}
      >
        {FeaturesData.filter(e=>e).map((feature) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={feature.id} >
            <Link to={feature.link} style={{ textDecoration: 'none' }}>
            <Card
              sx={{
                background: "#fff",
                borderRadius: "10px",
                p: "15px 20px",
                mb: "15px",
                boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  
                }}
              >
                <Box>
                  <Typography
                    variant="h1"
                    sx={{ fontSize: 14, fontWeight: 700 }}
                 textAlign={"center"}
                  >
                    {feature.title}
                  </Typography>
                  <Typography variant="p"  fontSize={16} fontWeight={700}  textAlign={"center"} width={"100%"} display={"block"}>
                    <span style={{color:"green"}}>{feature.subTitle}</span>
                  </Typography>
                </Box>

                
              </Box>

            
            </Card></Link>
          </Grid>
        ))}
        
      </Grid>
    </>
  );
};

export default Features;

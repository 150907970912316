import React from "react";
import { useState, useMemo } from "react";
import ListTable from "../../utils/Table";

export default function RecentlyAddedMovie({ recently_added_data }) {
  console.log(recently_added_data, "110110")
  const [tableData, setTableData] = useState({
    tableTitle: "Recently Added Videos",
    disableDelete: true,
    rowsPerPage: 5,
    // fixedRow:5,
    tableHead: [
      {
        id: 'channel_name',
        label: 'Name',
        link: "/Channel/ChannelDetails",
        color: "var(--gradientColor2)",
        // subText: "video_subcategory",
        width: "auto",
      },
      {
        id: "category",
        label: "Category"
      },
      {
        id: 'channel_image',
        label: 'Image',
        isImage: true
      },



      // {
      //   id: 'movie_ownership',
      //   label: 'Ownership',
      // },
      ,
      {
        id: 'language',
        label: 'Language',
      },
      {
        id:'channel_access',
        label:'Access'
      }

    ],
    tableBody: recently_added_data || []
  })

  const [form, setForm] = useState({});
  const [isEdit, setIsEdit] = useState(false);





  useMemo(() => {

    if (recently_added_data != undefined) {
      const temp = tableData
      temp.tableBody = recently_added_data?.map((ele)=>({...ele,category:ele?.category?.category_name ,language : ele?.language?.language_name})) || []

      setTableData({ ...temp })
    }
  }, [recently_added_data])



  return (
    <>


      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
      />

    </>
  );
}


import { CHANNEL, CHANNELDETAILS, MESSAGE } from '../constants/actionTypes'
import * as api from '../api/index.js';




  export const channel_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.channel_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const channel_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.channel_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_channel_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_channel_list(formData);
      dispatch({ type: CHANNEL, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const channel_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.channel_delete(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const channel_details = (formData) => async (dispatch) => {
    try {
      const { data } = await api.channel_details(formData);
      dispatch({ type: CHANNELDETAILS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
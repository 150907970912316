import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import { language_create, language_delete, language_update, all_language_list } from "../../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import { useLocation,useNavigate } from "react-router-dom";
import { all_genre_list, genre_create, genre_delete, genre_update,  } from "../../../actions/Masters/genre";
import { slider_video_create, slider_video_list, slider_video_update } from "../../../actions/Masters/introVideo";


export default function IntroVideo() {
	const dispatch = useDispatch()
	const location = useLocation();
	const navigate = useNavigate()
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState({});



	const formStructure = [
		{
			id: "1",
			type: "inputBox",
			title: "Add Video Title",
			name: "video_name",
			maxLength: 15,
			regex: /^[a-zA-Z\s]+$/,
			required: true
		},

		{ type: "undefined" },
		{
			id: "3",
			type: "file1",
			title: "Upload Video",
			name: "video_file",
			// subtitle: "(Resolution : 512px x 512px) *",
			// subsubsubtitle: "Support only JPG,PNG,JPEG",
			// subsubtitle: "Max File Size 1MB",
			// required: true
		},

		{
			id: "5",
			type: "button",
			title: "Update" ,
		},
	];
	const formTitle = "Intro Video";
	const slider_video = useSelector((state) => state?.masters?.slider_video?.data[0]);

	useMemo(()=>{
		setForm({...slider_video})
	},[slider_video])
	useEffect(() => {
		dispatch(slider_video_list())
	}, [])

	// useMemo(() => {

	// 	if (slider_video?.statuscode == 200) {
	// 		const temp = tableData
	// 		temp.tableBody = slider_video?.data
	// 		setTableData({ ...temp })
	// 	}
	// }, [slider_video])
	const message = useSelector((state) => state.layout.message);

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));

			dispatch(slider_video_update(data))
			navigate("/Dashboard/Dashboard")
		


	};


	return (
		<>


		
			<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					// tableData={tableData}
					// setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
	
			
		</>
	);
}

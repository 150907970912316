import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "./../utils/Table";
import { Link } from "react-router-dom";
import { movie_watch_user_list } from "../../actions/Movie/video";
import { episode_watch_user_list } from "./../../actions/WebSeries/episode";

import { useDispatch, useSelector } from "react-redux";
export default function ValueAddedCustomer({ data }) {
  console.log(data,"newdis")
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState({
    tableTitle: "Customers Who Have Seen",
    disableDelete: true,
    tableHead: [
      {
        id: "name",
        label: "Name",
        // link: "/Customer/CustomerDetail/CustomerDetail",
        // color:"var(--gradientColor1)",
        width: "auto",
      },
      // {
      //   id: "email",
      //   label: "Email",
      // },
      // {
      // 	id: "mobileNumber",
      // 	label: "Mobile No",
      // },
{
  id: "count",
  label:"View Count"
}
     
    ],
    tableBody: [],
  });
  const [form, setForm] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  // const customers = useSelector((state) => state.movies.movie_watch_user);
  // useEffect(() => {
  //   const data = new FormData();
  //   data.append("id", id);
  //   dispatch(movie_watch_user_list(data));
  // }, []);
  useMemo(() => {
    if (data) {
      const temp = tableData;
      temp.tableBody = data?.map((ele) => ({
        ...ele,
        name:
         
            <Link
              to="/Customer/CustomerDetail/CustomerDetail"
              state={{ id: ele?.customer?.id }}
            >
              <p style={{ color: "black" }}>{ele?.customer?.mobile_number}</p>
            </Link>,
            
            email : ele?.customer?.email,
            // mobileNumber : ele?.user?.mobileNumber
         
      })) || {};
      setTableData({ ...temp });
    }
  }, [data]);

  return (
    <>
      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
      />
    </>
  );
}

import {MESSAGE, SLIDERVIDEO } from '../../constants/actionTypes';
import * as api from '../../api/index.js';

export const slider_video_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.slider_video_create(formData);
      
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  
export const slider_video_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.slider_video_update(formData);
      
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const slider_video_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.slider_video_list(formData);
      
      dispatch({ type: SLIDERVIDEO, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  
import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/PageTitle.module.css";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import { TableData } from "./TableData";
import ViewChange from "../utils/ViewChange";
import { FormStructure } from "./FormStructure";

// import { all_distributor_list } from "../../../actions/distributor";
// import { all_genre_list } from "../../../actions/Masters/genre";
import { all_language_list } from "../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Export from "../utils/Export";
import Reload from "../utils/Reload";
import { all_channel_list, channel_create, channel_delete, channel_update } from "../../actions/channel";
import { all_category_list } from "../../actions/Masters/category";
import { all_subcategory_list } from "../../actions/Masters/subcategory";
import { all_genre_list } from "../../actions/Masters/genre";
export default function Channel() {
  const user = useSelector((state) => state.layout.profile);
  const role = useSelector((state) => state.layout.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
	const deleteChannel = useSelector((state) => state.layout.message);

  const rights = useSelector((state) => state.layout.rights);
  const distributor = useSelector((state) => state.layout.distributor);
  const location = useLocation();
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  const [flag, setFlag] = useState(false);

  const tempTableData = {
    ...TableData(),
    disableDelete: role !== "Distributor" ? false : true,

    deleteRecord: channel_delete,
    // updateRecord: series_update,
    // deleteAccess: rights?.["Web Series"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const tempFormStruct = FormStructure(isEdit);
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);

  const [formTitle, setFormTitle] = useState("Channel");
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
      // const temp = formStructure
      // temp[11]["display"] = "block";
      // temp[11]["required"] = true;
    } else {
      setView("view_all");
      setForm({
        set_on_dashboard: "Yes",
        status: "Active",
      });
    }
    const temp = formStructure;
    tempFormStruct.map((ele, j) => (temp[j] = ele));
    setFormStructure([...temp]);
  }, [isEdit, role]);
  const path = location?.pathname.split("/")[2];
  useEffect(() => {
    setView(path != "Channel" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {

        set_on_dashboard: "Yes",
        status: "Active",

      }
    );
    setIsEdit(path == "EditChannel");
  }, [location]);

  const Channel = useSelector((state) => state.channels.channels);


  useEffect(() => {
    if (user?.id) {
      const data = new FormData();
      data.append("id", user?.id);

      dispatch(all_channel_list(data));
    }
  }, [user?.id]);

  useMemo(() => {

		if (deleteChannel?.statuscode == 200) {
			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				dispatch(all_channel_list());
				setView("view_all");
			}, 1000);
		}
		
	}, [deleteChannel])
  const handleFileChange = (event) => {
    const file = event.target.files[0];

        setForm({
          ...form,
          [event.target.name]: file,
        });
   
    }

  useMemo(() => {
    if (Channel?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = Channel?.data.map((ele) => ({
        ...ele,
        category: ele?.category?.category_name,
        subcategory: ele?.subcategory?.subcategory_name,
        language: ele?.language?.language_name,
        genre:ele?.genre?.genre_name
      }));
      // temp.filterColumn[3]["options"] = [
      //   ...new Set(series?.data?.map((value) => value?.created_by?.firstName + "")),
      // ];
      setTableData({ ...temp });
    }
  }, [Channel]);
  const language = useSelector((state) => state.masters.languages);
  const categories = useSelector((state) => state.masters.categories);
  const subcategories = useSelector((state) => state.masters.subcategories);
  const genre = useSelector((state) => state.masters?.genre);

  useEffect(() => {
    if (language?.statuscode != 200)
      dispatch(all_language_list());
    if (categories?.statuscode != 200)
      dispatch(all_category_list());
    if (subcategories?.statuscode != 200)
      dispatch(all_subcategory_list());
    if (genre?.statuscode != 200)
     dispatch(all_genre_list());

  }, [user]);

  useMemo(() => {
    if (genre?.statuscode == 200) {
      const temp = formStructure;
      temp[7]["options"] = genre?.data.map(
        (genre) => genre?.genre_name
      );

      setFormStructure([...temp]);
      // const tempFilter = tableData;
      // tempFilter["filterColumn"][2]["options"] = genre?.data.map(
      //   (genre) => genre?.genre_name
      // );

      // setTableData({ ...tempFilter });
    }
  }, [genre, view]);


  useMemo(() => {
    if (categories?.statuscode == 200) {
      const temp = formStructure;
      temp[0]["options"] = categories?.data.map(
        (category) => category?.category_name
      );

      setFormStructure([...temp]);
      const tempFilter = tableData;
      tempFilter["filterColumn"][2]["options"] = categories?.data.map(
        (category) => category?.category_name
      );

      setTableData({ ...tempFilter });
    }
  }, [categories, view]);
  useMemo(() => {
    if (subcategories?.statuscode == 200) {
      const temp = formStructure;
      const tempForm = form;
      const tempFilter = tableData;
      // tempFilter["filterColumn"][3]["options"] = subcategories?.data;

      if (form?.category != undefined) {
        temp[1]["options"] = subcategories?.data
          .map(
            (subcategory) =>
              subcategory?.category?.category_name == form?.category &&
              subcategory?.subcategory_name
          )
          .filter((e) => e);
        tempForm.subcategory = temp[1]["options"].includes(
          form?.subcategory
        )
          ? form?.subcategory
          : "";
        // tempFilter["filterColumn"][3]["options"] = subcategories?.data.map((subcategory) => (subcategory?.category?.category_name == form?.category && subcategory?.subcategory_name))
      } else {
        temp[1]["options"] = subcategories?.data.map(
          (subcategory) => subcategory?.subcategory_name
        );
      }
      setFormStructure([...temp]);
      setForm({ ...tempForm });
      setTableData({ ...tempFilter });
    }
  }, [subcategories, form?.category, view]);
  useMemo(() => {
    if (language?.statuscode == 200) {
      const temp = formStructure;
      temp[5]["options"] = language?.data.map(
        (language) => language?.language_name
      );

      setFormStructure([...temp]);
    }
    if (language?.statuscode == 200) {
      const tempTableData = tableData;
      tempTableData.filterColumn[1]["options"] = language?.data.map(
        (language) => language?.language_name
      );

      setTableData({ ...tempTableData });
    }
  }, [language, form?.language, view]);

  useMemo(() => {
    if (form?.channel_access == "Paid") {
      const temp = formStructure
      temp[4]["display"] = "block"
      setFormStructure([...temp])
    } else {
      const temp = formStructure
      temp[4]["display"] = "none"
      setFormStructure([...temp])
    }
  }, [form?.channel_access])

  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const temp_movie_category = form;

    temp_movie_category["templanguage"] = form?.["language"];
    temp_movie_category["tempcategory"] = form?.["category"];
    temp_movie_category["tempsubcategory"] = form?.["subcategory"];
    temp_movie_category["tempchannel_image"] = form?.["channel_image"];
    temp_movie_category["tempgenre"] = form?.["genre"];

    temp_movie_category["language"] = language?.data
      .map(
        (option) =>
          form?.["language"]?.includes(option.language_name) && option.id
      )
      .filter((e) => e);
    temp_movie_category["category"] = categories?.data
      .map(
        (option) =>
          form?.["category"]?.includes(option.category_name) && option.id
      )
      .filter((e) => e);
      temp_movie_category["genre"] = genre?.data
      .map(
        (option) =>
          form?.["genre"]?.includes(option.genre_name) && option.id
      )
      .filter((e) => e);
      temp_movie_category["subcategory"] = subcategories?.data
      ?.filter((option) => form?.["subcategory"] == option.subcategory_name)
      .map((option) => option.id);
    // temp_movie_category["subcategory"] = subcategories?.data
    //   .map(
    //     (option) =>
    //       form?.["subcategory"]?.includes(option.subcategory_name) && option.id
    //   )
    //   .filter((e) => e);

    temp_movie_category["created_by"] = user?.id;
    setForm({
      ...temp_movie_category,
    });

    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    if (isEdit) {
      dispatch(channel_update(data));
    } else {
      dispatch(channel_create(data));
    }
    setFlag(true);
  };
  useMemo(() => {
    if (message?.statuscode == 200 && flag) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });
      setIsEdit(false);
      setForm({
        set_on_dashboard: "Yes",
        status: "Active",
      });
      setFlag(false);
      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);

        dispatch(all_channel_list(data));
        navigate("/Channel/Channel/", { state: { view: "view_all" } });
        setView("view_all");
      }, 1000);
    } else {
      const tempForm = form;
      tempForm["channel_image"] = form?.tempchannel_image;
      tempForm["language"] = form?.templanguage;
      tempForm["subcategory"] = form?.tempsubcategory;
      tempForm["category"] = form?.tempcategory;
      tempForm["genre"] = form?.tempgenre;

      setForm({ ...tempForm });
    }
  }, [message]);
  return (
    <>

      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        access={rights?.["Channel"]?.["create"] == "true"}
        create_new={"/Channel/CreateChannel/"}
        view_all={"/Channel/Channel/"}
        form={form}
        export_excel={
          <Export
            fileName={"Series"}
            access={rights?.["Web Series"]?.["export_excel"] == "true"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead.map((value) => value.label)}
          />
        }
        reload={<Reload isClubed={true} />}
      />

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Channel/EditChannel/"}
        />
      )}
    </>
  );
}

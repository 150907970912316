import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Channel from '../components/Channel/Channel';
import ProductDetails from "../components/ProductDetails/ProductDetailsContent"

function ChannelModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateChannel' element={<Layout children={Channel()} />} />
                < Route path='/EditChannel' element={<Layout children={Channel()} />} />
                < Route path='/Channel' element={<Layout children={Channel()} />} />
                < Route path='/ChannelDetails' element={<Layout children={ProductDetails()} />} />
            </Routes>
        </div>
    )
}
export default ChannelModule
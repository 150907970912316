import React from "react";

import styles from "./Profile.module.css";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import profile from "./../../images/photo.png";
import { IMAGE } from "./../../api/index";
const ProfileContent = ({ data }) => {
//   console.log(data, "12345");
  return (
    <>
      <div className={styles.profileBox}>
        <div className={styles.profileInfoContent}>
          <Card
            sx={{
              textAlign: "center",
              boxShadow:
                "var(--gradientColorLightest2) 0px 6px 12px -2px, var(--gradientColorLightest1) 0px 3px 7px -3px",
              borderRadius: "10px",
              p: "20px 15px",
            }}
          >
            {/* <img
							src={IMAGE+(data?.profile?.map((ele,index)=> ele?.avatar?.avatarImage))}
							alt="Member"
							height={"150px"}
							className="borRadius100"
						/> */}
            <img
              src={IMAGE + data?.profile?.avatar?.avatarImage}
              alt="Member"
              height={"150px"}
              className="borRadius100"
            />
            <Typography
              as="h4"
              sx={{
                fontSize: 16,
                fontWeight: 500,
                mt: "10px",
              }}
            >
              {data?.data?.firstName + " " + data?.data?.lastName}
            </Typography>

            <Typography
              sx={{
                fontSize: 13,
                color: "#A9A9C8",
              }}
            >
              {data?.data?.email}
            </Typography>
            <Typography
              sx={{
                fontSize: 13,
                color: "#A9A9C8",
              }}
            >
              {data?.data?.mobileNumber}
            </Typography>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ProfileContent;

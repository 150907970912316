import * as actionType from "../constants/actionTypes";

const channelReducer = (state = { channelData: null }, action) => {
	switch (action.type) {
		
		case actionType.CHANNEL:
			return {...state,channels: action.payload};
		case actionType.CHANNELDETAILS:
			return {...state,channel: action.payload};
		default:
			return state;
	}
};

export default channelReducer;

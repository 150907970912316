import React, { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import styles from "./Authentication.module.css";
import './Authentication.css'
import favicon from "./../../images/Change_pswd.png"
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Otp_verify, forgot_password } from "../../actions/authenticate";
import EmailIcon from '@mui/icons-material/Email';
import { InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
const OtpForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [otp, setOtp] = React.useState('')
  const [loading, setLoading] = React.useState(false);

  const handleChange = (newValue) => {
    setOtp(newValue)
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)
    // location.state.username == 'behindwoods.master@gmail.com' ?
    // dispatch(Otp_verify({ username: location?.state?.username, OTP: otp })) :
    dispatch(Otp_verify({ username: location?.state, OTP: otp }))

  };

  const message = useSelector((state) => state.layout.message1);
  // console.log(location, "33333")

  useMemo(() => {
    if (message?.statuscode == 200) {
      setTimeout(() => {
        setLoading(true)
        // location.state != "behindwoods.master@gmail.com" ?


        // navigate("/Authentication/UpdatePassword", { state:{ username: "behindwoods.master@gmail.com" } }) :
        navigate("/Authentication/ChangePassword", { state: location?.state })

      }, 1000)
    }
    else {
      setLoading(false)
    }
  }, [message])
  const isLoggedIn = useSelector((state) => state.layout.loggedin)
  if (isLoggedIn == true)
    return (
      <>
        <div className={styles.authenticationBox + " dark"}>
          <Box
            component="main"
            sx={{
              maxWidth: "450px",
              // mr: "10%",
              padding: "20px",
              borderRadius: "10px",
              // boxShadow: "var(--gradientColor2) -2px -2px 5px 1px,var(--gradientColor2) -2px 2px 5px 1px,var(--gradientColor2) 2px -2px 5px 1px, var(--gradientColor1) 2px 2px 5px 1px, var(--gradientColor1) -2px 2px 5px 1px, var(--gradientColor1) 2px -2px 5px 1px",
              // boxShadow: "var(--gradientColorLighter2) 0px 6px 5px -2px, var(--gradientColorLighter1) 0px 3px 7px -3px",
              // boxShadow: "#dca010 -2px -2px 5px 1px,#dca010 -2px 2px 5px 1px,#dca010 2px -2px 5px 1px, var(--gradientColor1) 2px 2px 5px 1px, var(--gradientColor1) -2px 2px 5px 1px, var(--gradientColor1) 2px -2px 5px 1px",
              boxShadow: "#8b0272 -2px -2px 5px 1px,#8b0272 -2px 2px 5px 1px,#8b0272 2px -2px 5px 1px, var(--gradientColor1) 2px 2px 5px 1px, var(--gradientColor1) -2px 2px 5px 1px, var(--gradientColor1) 2px -2px 5px 1px",
              

            }}
          >
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box>


                <Box component="form" sx={{ backgroundColor: "white", borderRadius: "10px", p: "20px" }} noValidate
                  onSubmit={handleSubmit}
                >
                  <Grid container justifyContent={"center"} sx={{ mb: 3 }}>
                    <img
                      src={favicon}
                      alt="favicon"
                      className={styles.favicon}
                      width={"100px"}
                    /></Grid>
                  <Typography as="h1" fontSize="24px" textAlign={"center"} fontWeight="700" mb="5px">
                    Forget Password

                  </Typography>

                  <Typography fontSize="15px" mb="5px" textAlign={"center"} >
                    Please enter one time password recived on {location?.state?.username ? location?.state?.username : location?.state}
                  </Typography>
                  <Box
                    sx={{
                      padding: "10px 20px",
                      borderRadius: "10px",
                      mb: "20px",
                    }}
                    className="bg-black"
                  >
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        {/* <Typography
                        component="label"
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          mb: "10px",
                          display: "block",
                        }}
                      >
                        Otp
                      </Typography> */}

                        {/* <TextField
                        required
                        fullWidth
                        id="username"
                        label="Email Address"
                        name="username"
                        autoComplete="username"
                        InputProps={{
                          style: { borderRadius: 8 },
                          endAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon style={{ color: "black !important" }} />
                            </InputAdornment>
                          ),
                        }}
                      /> */}
                        <MuiOtpInput value={otp} onChange={handleChange} />
                      </Grid>

                    </Grid> {message?.message}
                  </Box>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={loading}
                    sx={{
                      mt: 1,
                      textTransform: "capitalize",
                      borderRadius: "8px",
                      fontWeight: "500",
                      fontSize: "16px",
                      padding: "12px 10px",
                      color: "#fff !important"
                    }}
                    style={{ background: "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)" }}
                  // onSubmit={handleOtp}
                  >
                    Submit
                  </Button>
                  <Box as="div" textAlign="center" mt="20px">
                    <Link
                      to="/Authentication/SignIn/"
                      className={"primaryColor text-decoration-none " + styles.whiteText}
                    >
                      <i className="ri-arrow-left-s-line"></i> Back to Sign in
                    </Link>

                  </Box>
                </Box>


              </Box>
            </Grid>
          </Box>
        </div>
        {/* <p className={styles.authFooter}>Copyright © {new Date().getFullYear()} <span style={{ color: "inherit", fontWeight: "600", marginLeft: "4px", marginRight: "4px" }}>24Seven Flix4u</span>  all rights reserved</p> */}
      {/* <p className={styles.authFooter}>Copyright © {new Date().getFullYear()} <span style={{ color: "inherit", fontWeight: "600", marginLeft: "4px", marginRight: "4px" }}>Tmart Platform Private Limited</span>  all rights reserved</p> */}
      <p className={styles.authFooter}>Designed and Developed by  <a href="https://icode49.com/" style={{color:"#e35466" , marginLeft:"5px" , marginRight:"5px"}} target="_blank"> iCode49 Technolabs.</a> All rights reserved 2024
      </p>


      </>
    );
  else {
    setTimeout(() => {
      navigate("/Authentication")
    }, 500)
  }
};

export default OtpForm;

import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import poster from "./../../images/poster.jpg";
import "./ProductDetailsContent.css";
import Features from "./Features";
import ProductProfile from "./ProductProfile";
import {movie_details} from "../../actions/Movie/video"
import {episode_details} from "./../../actions/WebSeries/episode"
import { useEffect,useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { IMAGE } from "./../../api/index";
import WatchedCustomers from './WatchedCustomers'
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { channel_details } from "../../actions/channel";

const ProductDetailsContent = () => {
	const dispatch=useDispatch()
	const location = useLocation()
	const role=useSelector((state) => state.layout.role)

// console.log(path1,"879665")
	const formData= new FormData()
	formData.append("id",location.state?.id)
	useMemo(()=>{
	dispatch(channel_details(formData))
// 	formData.append("series_type" ,"Web Series")
// else formData.append("series_type" ,"Super Short Series")
	},[location.state?.id])
	const data =useSelector((state) =>state?.channels?.channel?.data)
	console.log(data , "NewData")
	const download =useSelector((state) => state.movies.movie?.Data)

	const cast = useSelector((state) =>state.movies.movie?.Cast);
	

	const earning =useSelector((state) =>state?.channels?.channel)
	// const earning1 =useSelector((state) =>state)

	
	const access=data?.movie_access
	return (
		<>
			<Grid
				container
				rowSpacing={1}
				justifyContent="center"
				columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
			>

				<Grid item spacing={4} xs={12} sm={12} md={6} lg={7} xl={7}>
					
					<Card
						sx={{
							boxShadow: "none",
							borderRadius: "10px",
							p: "15px 25px",
							mb: "15px",
						}}
					>
						<Box mt={2}>
							<Tabs className="product-details-tabs">
								<TabList>
									<Tab>Channel</Tab>
								</TabList>

								<TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "25px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",
											
										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												mb: "15px",
												fontWeight: 500,
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											<span style={{color:"#fff"}}>Channel Detail </span>
										</Typography>

										<Box>
										
										<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Channel Category:
												</span>
												{data?.category?.category_name}
											</Typography>
											{	<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Channel Subcategory:
												</span>
												{data?.subcategory?.subcategory_name}

											</Typography>}
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Channel Language:
												</span>
												{data?.language?.language_name}
											</Typography>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Genre:
												</span>
												{/* {data?.video_tag?.map((ele)=>ele?.tag_title + " ")} */}
												{data?.genre?.genre_name}
											</Typography>	
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Channel Access:
												</span>
												{data?.channel_access}
											</Typography>	
											{data?.channel_access == "Paid" &&
												
												<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Channel Price:
												</span>
												{parseFloat(data?.channel_price).toFixed(2)}
											</Typography>	}
											<Typography
											sx={{
												fontSize: 13,
												mt: "10px",
												display: "flex",
											}}
										>
											<span
												style={{
													width: "150px",
													fontWeight: "500",
												}}
												className="mr-10px"
											>
												Chaneel Number :
											</span>
											{data?.channel_number}
										</Typography>

											<Typography
											sx={{
												fontSize: 13,
												mt: "10px",
												display: "flex",
											}}
										>
											<span
												style={{
													width: "150px",
													fontWeight: "500",
												}}
												className="mr-10px"
											>
												Set On Dashboard ? :
											</span>
											{data?.set_on_dashboard}
										</Typography>
										
										
											
										</Box>
									</Card>
								</TabPanel>
							
							
							
								
								
							</Tabs>
						</Box>
					</Card>
					
					{/* <Grid
						container
						item
						xs={12}
						md={12}
						lg={12}
						xl={12}
						rowSpacing={1}
						justifyContent="space-evenly"
						columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
					>
						<Card
							sx={{
								boxShadow: "none",
								borderRadius: "10px",
								p: "25px",
								mb: "15px",
								width: "100%",
								display: "flex",
								justifyContent: "center",
								background: "transparent",
								textAlign: "center",
								color: "black",
							}}
						>
							<div>
								<img
									src={IMAGE+(data?.movie_poster||data?.episode_poster)}
									alt="Image"
									style={{
										display: "block",
										borderRadius: "10px",
										margin: "0 auto",
										height: "250px",
									}}
								/>
								<p>Poster</p>
							</div>
						</Card>
					
					</Grid> */}
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={5} xl={5} key={"sdcsdc"}>
					<ProductProfile data={data} />
					<Features earning={earning}/>
					
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
			{<WatchedCustomers data={earning?.channel_visited_customer}/>}</Grid>

				{/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
					<Card sx={{
						boxShadow: "none",
						borderRadius: "10px",
						p: "15px 25px",
						mb: "15px",
					}} >
						<Box>

							<Card sx={{
								boxShadow: "none",
								borderRadius: "10px",
								p: "25px 20px",
								mb: "10px",
								backgroundColor: "#F7FAFF",

							}}>
								<Typography
									as="h3"
									sx={{
										fontSize: 16,
										mb: "15px",
										fontWeight: 500,
										mt: "-25px",
										ml: "-25px",
										mr: "-25px",
										padding: "10px 25px",
										borderRadius: "8px 8px 0px 0px",
										background:
											"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

										color: "black",
									}}
								>
									<span style={{color:"#fff"}}>Subtitle List</span>
								</Typography>
								<Table>
									<TableHead>
										<TableRow style={{ fontWeight: 900, fontSize: "25px" }}>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>Language</TableCell>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>File</TableCell>
										</TableRow>
									</TableHead>
									{SubtitleCount !== 0 ?
										<TableBody>
											{Subtitle_list?.map((sub) => (

												<TableRow>
													<TableCell>
														{sub?.subtitle_language}
													</TableCell>
													<TableCell>
														<a href={sub?.subtitle_file} target="_blank" download="new-file">{sub?.subtitle_file.split("/")[5]}</a>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
										: <TableBody align="center">

											No subtitle is available for this Movie
										</TableBody>}

								</Table>

							</Card>


						</Box>

					</Card>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
					<Card sx={{
						boxShadow: "none",
						borderRadius: "10px",
						p: "15px 25px",
						mb: "15px",
					}} >
						<Box>

							<Card sx={{
								boxShadow: "none",
								borderRadius: "10px",
								p: "25px 20px",
								mb: "10px",
								backgroundColor: "#F7FAFF",

							}}>
								<Typography
									as="h3"
									sx={{
										fontSize: 16,
										mb: "15px",
										fontWeight: 500,
										mt: "-25px",
										ml: "-25px",
										mr: "-25px",
										padding: "10px 25px",
										borderRadius: "8px 8px 0px 0px",
										background:
											"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

										color: "black",
									}}
								>
									<span style={{color:"#fff"}}>Audio File List</span>
								</Typography>
								<Table>
									<TableHead>
										<TableRow style={{ fontWeight: 900, fontSize: "25px" }}>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>Audio</TableCell>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>File</TableCell>
										</TableRow>
									</TableHead>
									{AudioCount !== 0 ?
										<TableBody>
											{Audio_list?.map((sub) => (
												<TableRow>
													<TableCell>
														{sub?.audio_language}
													</TableCell>
													<TableCell>
														<a href={sub?.audio_file} target="_blank" download="new-file">{sub?.audio_file.split("/")[5]}</a>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
										: <TableBody align="center">

											No Audio files are available for this Movie
										</TableBody>}

								</Table>

							</Card>


						</Box>

					</Card>
				</Grid> */}
			</Grid>
		</>
	);
};

export default ProductDetailsContent;

import { combineReducers } from 'redux';
import masters from './masters';
import subscriptions from './subscriptions'
import customers from './customers'
import merchandise from './merchandise'
import movies from './movies'
import transactions from "./transactions"
import watchhours from "./watchhours"
import webseries from './webseries';
import setting from './setting'
import dashboard from './dashboard'
import layout from "./layout"
import highlight from "./highlight"
import producer from './producer'
import promotion from "./promotion"
import subtitle from "./subtitleReducer"
import complaints from "./complaints"
import videosubmission from './videosubmission'
import channels from "./channel"
export const reducers = combineReducers({ masters,videosubmission, channels, complaints,  layout, dashboard, setting, subscriptions, customers,highlight, promotion, merchandise, movies,subtitle,  transactions,producer, watchhours, webseries });
